<template>
  <ValidationObserver v-slot="{handleSubmit}">
    <m-card :title="$t('shared.add')">
      <v-form>
        <v-row dense>
          <v-col cols="12" sm="6" md="4">
            <c-input v-model="model.name" :label="$t('shared.name')" :rules="'required|max:30'" />
          </v-col>

          <v-col cols="12" sm="6" md="4">
            <c-input
              select
              v-model="model.payType"
              :label="$t('paymentConfigs.payType')"
              :rules="'required'"
              :items="payTypes"
              @input="model.handlerType = null"
            />
          </v-col>

          <v-col cols="12" sm="6" md="4">
            <c-input
              select
              v-model="model.handlerType"
              :label="$t('paymentConfigs.handlerType')"
              :rules="'required'"
              :items="handlerTypes"
            />
          </v-col>

          <v-col cols="12" sm="6" md="4">
            <c-input v-model="model.merchantId" :label="$t('paymentConfigs.merchantId')" dir="ltr" />
          </v-col>

          <v-col cols="12" sm="6" md="4">
            <c-input
              v-model="model.minimumPayable"
              :label="$t('paymentConfigs.minimumPayable')"
              dir="ltr"
              :suffix="$t('shared.IRR')"
              :rules="'numeric'"
            />
          </v-col>

          <v-col cols="12" sm="6" md="4">
            <c-input v-model="model.userName" :label="$t('shared.username')" dir="ltr" />
          </v-col>

          <v-col cols="12" sm="6" md="4">
            <c-input v-model="model.password" :label="$t('shared.password')" dir="ltr" />
          </v-col>

          <v-col cols="12" sm="6" md="4">
            <c-input v-model="model.sortOrder" :label="$t('shared.sortOrder')" dir="ltr" :rules="'numeric'" />
          </v-col>

          <v-col cols="12" sm="6" md="4">
            <uploader v-model="model.image" :label="$t('shared.image')" :name="$t('shared.image')" />
          </v-col>

          <v-col cols="12" sm="6" md="4" class="d-flex align-center">
            <v-switch v-model="model.active" :label="$t('shared.active')" inset />
          </v-col>

          <v-col cols="12" sm="6" md="4" class="d-flex align-center">
            <v-switch v-model="model.web" :label="$t('shared.web')" inset />
          </v-col>

          <v-col cols="12" sm="6" md="4" class="d-flex align-center">
            <v-switch v-model="model.android" :label="$t('shared.android')" inset />
          </v-col>

          <v-col cols="12" sm="6" md="4" class="d-flex align-center">
            <v-switch v-model="model.ios" :label="$t('shared.ios')" inset />
          </v-col>
        </v-row>
      </v-form>

      <template #actions>
        <page-footer @submit="handleSubmit(submit)" :loading="progressing" />
      </template>
    </m-card>
  </ValidationObserver>
</template>

<script>
import EditForm from './EditForm'
export default {
  name: 'Create',
  mixins: [EditForm],
  methods: {
    submit() {
      this.progressing = true
      return this.$api.paymentConfigs
        .create(this.model)
        .then((res) => {
          this.model = res
          this.progressing = false
          this.$showSuccess(this.$t('paymentConfigs.paymentConfigAdded'))
        })
        .catch((err) => {
          this.progressing = false
          this.$showError(err)
        })
    }
  }
}
</script>
